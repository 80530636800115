import React from 'react';
import PropTypes from 'prop-types';
import { getCookie } from 'Services/core__services';
import { FSBTheme } from 'Services/core__fsb-theme';
import { BV } from 'CORE__UI/apps/ReleaseVersionApp/core__release-version';

const ReleaseVersionReact = ({ appConfig }) => {
  const buildVersion = getCookie('buildVersion');
  return (
    <FSBTheme theme={appConfig?.theme}>
      {buildVersion && <BV>{`Build Version: ${buildVersion}`}</BV>}
    </FSBTheme>
  );
};

ReleaseVersionReact.propTypes = {
  appConfig: {
    theme: PropTypes.string,
  },
};

export default ReleaseVersionReact;
