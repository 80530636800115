import React from 'react';
import ReactDOM from 'react-dom';
import ReleaseVersionReact from './component/core__release-version-react';

class ReleaseVersionApp {
  constructor(properties) {
    Object.assign(this, properties);

    if (this.nodeSSR) return;

    this.start();
  }

  start() {
    const ReactInstance = this.getReactApp();
    const jsx = <ReactInstance appConfig={this.appConfig} />;

    ReactDOM['render'](jsx, this.appSection);
  }

  getReactApp() {
    return ReleaseVersionReact;
  }
}

export default ReleaseVersionApp;
